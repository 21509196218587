<template>
  <div>
    <div id="editor"></div>
  </div>
</template>

<script>
  import E from "wangeditor";
  import config from "@/resources/js/config";

  export default {
    name: 'WangEditor',
    data() {
      return {
        editor: ''
      }
    }, created: function () {
      //主要是该方法
      this.$nextTick(function () {
        this.initEditor();
      })
    }, methods: {
      initEditor() {
        //获取DOM
        this.editor = new E(editor);
        this.initEditorConfig();
        // 配置菜单栏，删减菜单，调整顺序
        this.editor.config.excludeMenus = [
          'emoticon',
          'video'
        ]
        // this.editor.config.uploadImgServer = `${window.location.origin}${config.BASE_URL}/uploads/uploadEditorFile` // 上传图片的接口地址
        this.editor.config.uploadImgServer = `${window.location.origin}${config.BASE_URL}/layeditupload/uploadlayeditimgs` // 上传图片的接口地址
        // this.editor.config.uploadImgServer = `${window.location.origin}/uploads/uploadEditorFile` // 上传图片的接口地址
        //this.editor.config.uploadImgServer = `/api/uploads/uploadEditorFile` // 上传图片的接口地址
        this.editor.config.uploadFileName = 'file' // formdata中的name属性
        this.editor.config.debug = true // 开启debug模式
        this.editor.config.uploadImgHeaders = {
          token: localStorage.getItem("token") || ''
        }
        this.editor.config.uploadImgHooks = {
          // 图片上传并返回结果，但图片插入错误时触发
          fail: function (xhr, editor, result) {
            console.log(result)
          },
          success: function (xhr, editor, result) {
            // 图片上传并返回结果，图片插入成功之后触发
            console.log(result, 'success')
          }
        }
          this.editor.create();
          this.createDisabled = true;
          this.$emit('onInit', this.editor);
        },
          //初始化副文本编辑器配置
          initEditorConfig()
        {

        }
      }

    }
</script>

<style scoped>

</style>
